import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'
import fileDownload from 'js-file-download'
import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoiceDocumentRequest } from '../types'

export function* downloadInvoiceDocumentSaga({
	payload,
}: PayloadAction<IInvoiceDocumentRequest>) {
	try {
		const document: ArrayBuffer = yield call(apiMiddleware, {
			url: app_config.services.downloadInvoiceDocument,
			method: 'POST',
			data: payload,
			responseType: 'blob',
		})

		if (payload.callback) {
			payload.callback(payload.id)
		}

		fileDownload(document, payload.name ?? '')
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
