import { call, put } from 'redux-saga/effects'

import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import {
	getInvoicesReadyToPayFailure,
	getInvoicesReadyToPaySuccess,
} from '../reducer'
import { IInvoicesReadyToPay } from '../types'

export function* getInvoicesReadyToPaySaga() {
	try {
		const response: IInvoicesReadyToPay = yield call(apiMiddleware, {
			url: app_config.services.getInvoicesReadyToPay,
			method: 'POST',
		})

		yield put(
			getInvoicesReadyToPaySuccess(response)
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(getInvoicesReadyToPayFailure())

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
