import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoiceDocumentRequest } from '../types'

export function* deleteInvoiceDocumentSaga({
	payload,
}: PayloadAction<IInvoiceDocumentRequest>) {
	try {
		const { message }: { message: string } = yield call(apiMiddleware, {
			url: app_config.services.deleteInvoiceDocument,
			method: 'POST',
			data: payload,
		})

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
