import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import { deleteWalletDataRequest, getWalletByAccIdRequest, updateWalletDataRequest } from 'redux/wallet/reducer';
import { deleteWalletDataSaga, getWalletByAccIdSaga, updateWalletDataSaga } from 'redux/wallet/saga';

import { sendRegistrationDataRequest } from './registration/reducer'
import { sendRegistrationDataSaga } from './registration/saga'

import { sendSingleSignOnDataRequest } from './singleSignOn/reducer'
import { sendSingleSignOnDataSaga } from './singleSignOn/saga'

import {
	sendVerificationRequest,
	resendVerificationRequest,
} from './verification/reducer'
import { sendVerificationSaga } from './verification/saga'
import { resendVerificationSaga } from './verification/resendSaga'

import {
	getUsersRequest,
	inviteUsersRequest,
	joinUserRequest,
} from './userList/reducer'
import { getUserListSaga } from './userList/sagas/getUserListSaga'
import { inviteUsersSaga } from './userList/sagas/inviteUsersSaga'
import { joinUserSaga } from './userList/sagas/joinUserSaga'

import {
	getMembersRequest,
	getMembersWithoutLoadingRequest,
	inviteMembersRequest,
	triggerTeamMemberRoleRequest,
	deleteTeamMemberRequest,
} from './memberList/reducer'
import { getMembersSaga } from './memberList/sagas/getMembersSaga'
import { inviteMembersSaga } from './memberList/sagas/inviteMembersSaga'
import { triggerTeamMemberRoleSaga } from './memberList/sagas/triggerTeamMemberRoleSaga'
import { deleteTeamMemberSaga } from './memberList/sagas/deleteTeamMemberSaga'

import { getMyUserRequest, deleteMyUserRequest } from './myUser/reducer'
import { getMyUserSaga, deleteMyUserSaga } from './myUser/saga'

import {
	changePasswordRequest,
	forgotPasswordRequest,
	updateUserRequest,
	signInRequest,
	otpVerifyRequest,
	otpValidateRequest,
	otpGenerateRequest,
	otpDisableRequest,
} from './authForm/reducer'

import { forgotPasswordSaga } from './authForm/sagas/forgotPasswordSaga'
import { changePasswordSaga } from './authForm/sagas/changePasswordSaga'
import { updateUserSaga } from './authForm/sagas/updateUserSaga'
import { signInSaga } from './authForm/sagas/signInSaga'

import { otpDisableSaga } from './authForm/sagas/otpDisableSaga'
import { otpGenerateSaga } from './authForm/sagas/otpGenerateSaga'
import { otpValidateSaga } from './authForm/sagas/otpValidateSaga'
import { otpVerifySaga } from './authForm/sagas/otpVerifySaga'

import {
	getUserRequest,
	getUserWalletAcceptedTokensRequest,
	getUserTeamDefaultApproversRequest,
	setUserTeamProfileRequest,
	setUserTeamSubscriptionRequest,
	setUserTeamApprovalSettingsRequest,
	setUserAccountRequest,
	setUserProfileRequest,
	setUserTeamRequest,
	createUserTeamRequest,
	discardWelcomeStepsRequest,
	resendUserInviteRequest,
	revokeUserInviteRequest,
	setUserWalletAcceptedTokenRequest,
	deleteUserAvatarRequest,
	uploadUserAvatarRequest,
	getUserWalletsRequest,
} from './user/reducer'
import {
	getUserSaga,
	getUserWalletAcceptedTokensSaga,
	getUserTeamDefaultApproversSaga,
	getUserWalletsSaga,
} from './user/saga'
import { setUserTeamProfile } from './user/setUserTeamProfile'
import { setUserTeamSubscription } from './user/setUserTeamSubscription'
import { setUserAccountSaga } from './user/setUserAccountSaga'
import { discardWelcomeStepsSaga } from './user/discardWelcomeStepsSaga'
import { setUserProfileSaga } from './user/setUserProfileSaga'
import { setUserTeamSaga } from './user/setUserTeamSaga'
import { createUserTeamSaga } from './user/createUserTeamSaga'
import { resendUserInviteSaga } from './user/resendUserInviteSaga'
import { revokeUserInviteSaga } from './user/revokeUserInviteSaga'
import { setUserWalletAcceptedTokenSaga } from './user/setUserWalletAcceptedTokenSaga'
import { deleteUserAvatarSaga } from './user/deleteUserAvatarSaga'
import { uploadUserAvatarSaga } from './user/uploadUserAvatarSaga'
import { setUserTeamApprovalSettings } from './user/setUserTeamApprovalSettings'

import {
	createInvoiceRequest,
	deleteInvoicesRequest,
	downloadInvoicePdfRequest,
	getInvoiceRequest,
	getInvoiceReviewsRequest,
	updateInvoiceRequest,
	updateInvoiceStatusRequest,
	updateInvoicesReadyToPayRequest,
	updateInvoicesAccountRequest,
	cloneInvoicesRequest,
	reviewInvoicesRequest,
	getInvoiceChangelogsRequest,
	archiveInvoicesRequest,
	deleteInvoiceDocumentRequest,
	downloadInvoiceDocumentRequest,
	updateInvoiceEditStatusRequest,
} from './invoice/reducer'
import { createInvoiceSaga } from './invoice/sagas/createInvoiceSaga'
import { updateInvoiceSaga, updateInvoiceEditStatusSaga } from './invoice/sagas/updateInvoiceSaga'
import { deleteInvoicesSaga } from './invoice/sagas/deleteInvoicesSaga'
import { downloadInvoicePdfSaga } from './invoice/sagas/downloadInvoicePdfSaga'
import { updateInvoiceStatusSaga } from './invoice/sagas/updateInvoiceStatusSaga'
import { updateInvoicesReadyToPaySaga } from './invoice/sagas/updateInvoicesReadyToPaySaga'
import { updateInvoicesAccountSaga } from './invoice/sagas/updateInvoicesAccountSaga'
import { cloneInvoicesSaga } from './invoice/sagas/cloneInvoicesSaga'
import { getInvoiceReviewsSaga } from './invoice/sagas/getInvoiceReviewsSaga'
import { reviewInvoicesSaga } from './invoice/sagas/reviewInvoicesSaga'
import { getInvoiceChangelogsSaga } from './invoice/sagas/getInvoiceChangelogsSaga'
import { archiveInvoicesSaga } from './invoice/sagas/archiveInvoicesSaga'
import { deleteInvoiceDocumentSaga } from './invoice/sagas/deleteInvoiceDocumentSaga'
import { downloadInvoiceDocumentSaga } from './invoice/sagas/downloadInvoiceDocumentSaga'

import { proceedToPaymentRequest } from './pay/reducer'
import { proceedToPaymentSaga } from './pay/sagas/proceedToPaymentSaga'

import {
	getInvoicesReadyToPayRequest,
	getInvoicesRequest,
} from './invoices/reducer'
import { getInvoicesSaga } from './invoices/sagas/getInvoicesSaga'
import {
	getInvoicesReadyToPaySaga
} from './invoices/sagas/getInvoicesReadyToPaySaga'

import { getInvoiceReportsRequest } from './invoiceReports/reducer'
import { getInvoiceReportsSaga } from './invoiceReports/sagas/getInvoiceReportsSaga'

import { getInvoiceSaga } from './invoice/sagas/getInvoiceSaga'

import { logoutRequest } from './logout/reducer'
import { logoutSaga } from './logout/sagas/logoutSaga'

import { sendWithdrawRequest } from './withdraw/reducer'
import { sendWithdrawSaga } from './withdraw/saga'

import { getInvoicesForCheckoutRequest } from './checkout/reducer'
import { getInvoicesForCheckoutSaga } from './checkout/sagas/getInvoicesForCheckoutSaga'

import {
	createTagRequest,
	updateTagRequest,
	deleteTagRequest,
} from './tag/reducer'
import { createTagSaga } from './tag/sagas/createTagSaga'
import { updateTagSaga } from './tag/sagas/updateTagSaga'
import { deleteTagSaga } from './tag/sagas/deleteTagSaga'

import { getCoinPricesRequest } from './coinPrices/reducer'
import { getCoinPricesSaga } from './coinPrices/saga'

export default function* root() {
	yield all([takeLatest(sendRegistrationDataRequest, sendRegistrationDataSaga)])
	yield all([takeLatest(sendSingleSignOnDataRequest, sendSingleSignOnDataSaga)])
	yield all([takeLatest(signInRequest, signInSaga)])
	yield all([takeLatest(logoutRequest, logoutSaga)])
	yield all([takeLatest(otpVerifyRequest, otpVerifySaga)])
	yield all([takeLatest(otpValidateRequest, otpValidateSaga)])
	yield all([takeLatest(otpGenerateRequest, otpGenerateSaga)])
	yield all([takeLatest(otpDisableRequest, otpDisableSaga)])
	yield all([takeLatest(sendVerificationRequest, sendVerificationSaga)])
	yield all([takeLatest(resendVerificationRequest, resendVerificationSaga)])
	yield all([takeLatest(getUsersRequest, getUserListSaga)])
	yield all([takeLatest(getMembersRequest, getMembersSaga)])
	yield all([takeLatest(getMembersWithoutLoadingRequest, getMembersSaga)])
	yield all([takeLatest(inviteMembersRequest, inviteMembersSaga)])
	yield all([takeLatest(triggerTeamMemberRoleRequest, triggerTeamMemberRoleSaga)])
	yield all([takeLatest(deleteTeamMemberRequest, deleteTeamMemberSaga)])
	yield all([takeLatest(getMyUserRequest, getMyUserSaga)])
	yield all([takeLatest(deleteMyUserRequest, deleteMyUserSaga)])
	yield all([takeLatest(getInvoicesRequest, getInvoicesSaga)])
	yield all([takeLatest(getInvoicesReadyToPayRequest, getInvoicesReadyToPaySaga)])
	yield all([takeLatest(getInvoiceReportsRequest, getInvoiceReportsSaga)])
	yield all([takeLatest(forgotPasswordRequest, forgotPasswordSaga)])
	yield all([takeLatest(changePasswordRequest, changePasswordSaga)])
	yield all([takeLatest(inviteUsersRequest, inviteUsersSaga)])
	yield all([takeLatest(joinUserRequest, joinUserSaga)])
	yield all([takeLatest(resendUserInviteRequest, resendUserInviteSaga)])
	yield all([takeLatest(revokeUserInviteRequest, revokeUserInviteSaga)])
	yield all([takeLatest(getUserRequest, getUserSaga)])
	yield all([
		takeLatest(
			getUserWalletAcceptedTokensRequest,
			getUserWalletAcceptedTokensSaga
		),
	])
	yield all([takeLatest(createInvoiceRequest, createInvoiceSaga)])
	yield all([takeLatest(setUserTeamProfileRequest, setUserTeamProfile)])
	yield all([takeLatest(setUserTeamSubscriptionRequest, setUserTeamSubscription)])
	yield all([takeLatest(setUserTeamApprovalSettingsRequest, setUserTeamApprovalSettings)])
	yield all([takeLatest(getUserTeamDefaultApproversRequest, getUserTeamDefaultApproversSaga)])
	yield all([takeLatest(getUserWalletsRequest, getUserWalletsSaga)])
	yield all([takeLatest(setUserProfileRequest, setUserProfileSaga)])
	yield all([takeLatest(setUserTeamRequest, setUserTeamSaga)])
	yield all([takeLatest(createUserTeamRequest, createUserTeamSaga)])
	yield all([takeLatest(deleteUserAvatarRequest, deleteUserAvatarSaga)])
	yield all([takeLatest(uploadUserAvatarRequest, uploadUserAvatarSaga)])
	yield all([takeLatest(setUserAccountRequest, setUserAccountSaga)])
	yield all([takeLatest(updateInvoiceRequest, updateInvoiceSaga)])
	yield all([takeLatest(deleteInvoicesRequest, deleteInvoicesSaga)])
	yield all([takeLatest(downloadInvoicePdfRequest, downloadInvoicePdfSaga)])
	yield all([takeLatest(cloneInvoicesRequest, cloneInvoicesSaga)])
	yield all([takeLatest(archiveInvoicesRequest, archiveInvoicesSaga)])
	yield all([takeLatest(reviewInvoicesRequest, reviewInvoicesSaga)])
	yield all([takeLatest(deleteInvoiceDocumentRequest, deleteInvoiceDocumentSaga)])
	yield all([takeEvery(downloadInvoiceDocumentRequest, downloadInvoiceDocumentSaga)])
	yield all([takeLatest(proceedToPaymentRequest, proceedToPaymentSaga)])
	yield all([takeLatest(updateUserRequest, updateUserSaga)])
	yield all([takeLatest(discardWelcomeStepsRequest, discardWelcomeStepsSaga)])
	yield all([takeLatest(updateInvoiceStatusRequest, updateInvoiceStatusSaga)])
	yield all([
		takeLatest(updateInvoicesReadyToPayRequest, updateInvoicesReadyToPaySaga),
	])
	yield all([
		takeLatest(updateInvoicesAccountRequest, updateInvoicesAccountSaga),
	])
	yield all([takeLatest(getInvoiceRequest, getInvoiceSaga)])
	yield all([takeLatest(getInvoiceReviewsRequest, getInvoiceReviewsSaga)])
	yield all([takeLatest(getInvoiceChangelogsRequest, getInvoiceChangelogsSaga)])
	yield all([takeLatest(sendWithdrawRequest, sendWithdrawSaga)])
	yield all([takeLatest(getInvoicesForCheckoutRequest, getInvoicesForCheckoutSaga)])
	yield all([
		takeLatest(
			setUserWalletAcceptedTokenRequest,
			setUserWalletAcceptedTokenSaga
		),
	])
	yield all([takeLatest(createTagRequest, createTagSaga)])
	yield all([takeLatest(updateTagRequest, updateTagSaga)])
	yield all([takeLatest(deleteTagRequest, deleteTagSaga)])
	yield all([takeLatest(getCoinPricesRequest, getCoinPricesSaga)])
	yield all([takeLatest(getWalletByAccIdRequest, getWalletByAccIdSaga)])
	yield all([takeLatest(updateWalletDataRequest, updateWalletDataSaga)])
	yield all([takeLatest(deleteWalletDataRequest, deleteWalletDataSaga)])
	yield all([takeLatest(updateInvoiceEditStatusRequest, updateInvoiceEditStatusSaga)])
}
