import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoice, IInvoiceUserReview } from '../types'

import {
	invoiceRequestComplete,
	invoiceRequestSuccess,
	setCurrentInvoiceUserReview,
} from '../reducer'

export function* getInvoiceSaga({
	payload,
}: PayloadAction<{ id: string; userId?: string }>) {
	try {
		const {
			data,
		}: {
			data: {
				invoice: IInvoice
				review?: IInvoiceUserReview
			}
		} = yield call(apiMiddleware, {
			url: `${app_config.services.getInvoice}/${payload.id}`,
		})

		yield put(setCurrentInvoiceUserReview(data.review))
		yield put(invoiceRequestSuccess(data.invoice))
	} catch (e) {
		yield put(invoiceRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
