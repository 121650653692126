import { PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import {
	deleteMyUserFailure,
	deleteMyUserSuccess,
	getMyUserFailure,
	getMyUserSuccess,
} from 'redux/myUser/reducer'
import { IMyUserRequest } from 'redux/myUser/types'
import { ITeamRoleUser } from 'redux/user/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

export function* getMyUserSaga({ payload }: PayloadAction<IMyUserRequest>) {
	try {
		const {
			data,
		}: {
			data: { user: ITeamRoleUser }
		} = yield call(apiMiddleware, {
			url: `${app_config.services.getMyUser}/${payload.id}`,
		})

		yield put(getMyUserSuccess(data.user))
	} catch (e) {
		yield put(getMyUserFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}

export function* deleteMyUserSaga({ payload }: PayloadAction<IMyUserRequest>) {
	try {
		const { message }: { message: string } = yield call(apiMiddleware, {
			url: app_config.services.deleteMyUser,
			method: 'POST',
			data: payload
		})

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)

		yield put(deleteMyUserSuccess())
	} catch (e) {
		yield put(deleteMyUserFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
