import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { getUsersRequest } from 'redux/userList/reducer'

import { proceedToPaymentSuccess, proceedToPaymentFailure } from '../reducer'
import { IPaymentRequest } from '../types'

export function* proceedToPaymentSaga({ payload }: PayloadAction<IPaymentRequest>) {
	try {
		yield call(apiMiddleware, {
			url: app_config.services.prepareInvoiceForPayment,
			method: 'POST',
			data: payload,
		})

		yield put(proceedToPaymentSuccess())

		yield put(getUsersRequest())
	} catch (e) {
		yield put(proceedToPaymentFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
