/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotificationState } from './types'

const initialState: INotificationState = {
	isOpen: false,
	message: '',
	notificationType: null,
	preventDuplicate: false,
}

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		onOpenNotification: (
			state,
			{
				payload,
			}: PayloadAction<Pick<INotificationState, 'message' | 'notificationType' | 'preventDuplicate'>>
		) => {
			state.isOpen = true
			state.message = payload.message || 'Something went wrong'
			state.notificationType = payload.notificationType
			state.preventDuplicate = payload.preventDuplicate ?? false
		},
		onCloseNotification: (state) => {
			state.isOpen = false
			state.message = ''
			state.notificationType = null
			state.preventDuplicate = false
		},
	},
})

export const { onOpenNotification, onCloseNotification } =
	notificationSlice.actions

export const { reducer } = notificationSlice
