/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IPaymentRequest, IPayState } from './types'

const initialState: IPayState = {
	isProceedPaymentLoading: false,
	isRequestSuccess: false,
}

const paySlice = createSlice({
	name: 'pay',
	initialState,
	reducers: {
		proceedToPaymentRequest: (state, { payload }: PayloadAction<IPaymentRequest>) => {
			state.isProceedPaymentLoading = true
		},

		proceedToPaymentSuccess: (state) => {
			state.isProceedPaymentLoading = false
			state.isRequestSuccess = true
		},

		proceedToPaymentFailure: (state) => {
			state.isProceedPaymentLoading = false
		},

		setIsRequestSuccessStatus: (state) => {
			state.isRequestSuccess = false
		},
	},
})

export const {
	proceedToPaymentRequest,
	proceedToPaymentSuccess,
	proceedToPaymentFailure,

	setIsRequestSuccessStatus,
} = paySlice.actions

export const { reducer } = paySlice
