export interface ICoinPrice {
	usd: number
}

export enum CoinPriceSymbols {
	USDT = 'tether',
	USDC = 'usd-coin',
	BUSD = 'binance-usd',
	GUSD = 'gemini-dollar',
	NEAR = 'near',
	ETH = 'ethereum',
	TRON = 'tron',
	POLYGON = 'matic-network',
	BSC = 'binancecoin',
}

export type ICoinPrices = {
	[key in CoinPriceSymbols]: ICoinPrice
}

export interface ICoinPricesState {
	isCoinPricesLoading: boolean
	coinPrices: ICoinPrices
}
