import { reducer as registrationReducer } from './registration/reducer'
import { reducer as notificationReducer } from './notification/reducer'
import { reducer as authFormReducer } from './authForm/reducer'
import { reducer as ssoReducer } from './singleSignOn/reducer'
import { reducer as verificationReducer } from './verification/reducer'
import { reducer as userListReducer } from './userList/reducer'
import { reducer as memberListReducer } from './memberList/reducer'
import { reducer as myUserReducer } from './myUser/reducer'
import { reducer as userReducer } from './user/reducer'
import { reducer as invoiceReducer } from './invoice/reducer'
import { reducer as payReducer } from './pay/reducer'
import { reducer as walletAccountReducer } from './walletAccount/reducer'
import { reducer as checkoutReducer } from './checkout/reducer'
import { reducer as mainPageControllerReducer } from './mainPageController/reducer'
import { reducer as invoicesReducer } from './invoices/reducer'
import { reducer as invoiceReportsReducer } from './invoiceReports/reducer'
import { reducer as logoutReducer } from './logout/reducer'
import { reducer as withdrawReducer } from './withdraw/reducer'
import { reducer as tagReducer } from './tag/reducer'
import { reducer as coinPricesReducer } from './coinPrices/reducer'
import { reducer as walletManagementReducer } from './wallet/reducer'

export const rootReducer = {
	notification: notificationReducer,
	registration: registrationReducer,
	authForm: authFormReducer,
	verification: verificationReducer,
	userList: userListReducer,
	memberList: memberListReducer,
	myUser: myUserReducer,
	user: userReducer,
	invoice: invoiceReducer,
	pay: payReducer,
	walletAccount: walletAccountReducer,
	checkout: checkoutReducer,
	mainPageController: mainPageControllerReducer,
	invoices: invoicesReducer,
	invoiceReports: invoiceReportsReducer,
	logout: logoutReducer,
	withdraw: withdrawReducer,
	singleSignOn: ssoReducer,
	tag: tagReducer,
	coinPrices: coinPricesReducer,
	activeWallet: walletManagementReducer,
}
