import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoice } from '../types'

import {
	cloneInvoicesRequestSuccess,
	invoiceRequestComplete,
	invoiceRequestSuccess,
} from '../reducer'

export function* cloneInvoicesSaga({
	payload,
}: PayloadAction<{ invoiceIds: string[] }>) {
	try {
		const {
			invoice,
		}: {
			invoice: IInvoice
		} = yield call(apiMiddleware, {
			url: app_config.services.cloneInvoice,
			method: 'POST',
			data: payload,
		})

		if (payload.invoiceIds.length === 1) {
			yield put(invoiceRequestSuccess(invoice))
		}

		yield put(cloneInvoicesRequestSuccess())
	} catch (e) {
		yield put(invoiceRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
