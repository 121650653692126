import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import {
	invoiceReviewsRequestFailure,
	invoiceReviewsRequestSuccess,
} from '../reducer'
import { IInvoiceReview } from '../types'

export function* getInvoiceReviewsSaga({
	payload,
}: PayloadAction<{ id: string }>) {
	try {
		const { reviews }: {
			reviews: IInvoiceReview[]
		} = yield call(apiMiddleware, {
			url: `${app_config.services.getInvoiceReviews}/${payload.id}`,
		})

		yield put(invoiceReviewsRequestSuccess(reviews))
	} catch (e) {
		yield put(invoiceReviewsRequestFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
