/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFormInputs } from 'components/SignInUpForm'
import {
	IAuthFormState,
	IChangePassword,
	IOtpGenerateResponse,
	IUpdateCheckPayload,
	IUpdatePayload,
} from './types'

const initialState: IAuthFormState = {
	isLoading: false,
	isOtpRequired: false,
	requestCompletedSuccess: false,
	requestOtpVerifySuccess: false,
	requestOtpValidateSuccess: false,
	requestOtpGenerateSuccess: false,
	requestOtpDisableSuccess: false,
	requestChangePasswordCompletedSuccess: false,
	otpGenerate: undefined,
	protocol: undefined,
	provider: undefined,
	ssoUserId: undefined,
	email: undefined,
	password: undefined,
}

const authFormSlice = createSlice({
	name: 'authForm',
	initialState,
	reducers: {
		forgotPasswordRequest: (
			state,
			{ payload }: PayloadAction<Pick<IFormInputs, 'email'>>
		) => {
			state.requestCompletedSuccess = false
			state.isLoading = true
		},

		setRequestCompletedSuccess: (state) => {
			state.isLoading = false
			state.requestCompletedSuccess = true
		},

		setRequestCompletedFailure: (state) => {
			state.isLoading = false
		},

		changePasswordRequest: (
			state,
			{ payload }: PayloadAction<IChangePassword>
		) => {
			state.requestChangePasswordCompletedSuccess = false
			state.isLoading = true
		},

		changePasswordSuccess: (state) => {
			state.requestChangePasswordCompletedSuccess = true
			state.isLoading = false
		},

		changePasswordFailure: (state) => {
			state.requestChangePasswordCompletedSuccess = false
			state.isLoading = false
		},

		setAuthFormLoading: (
			state,
			{ payload }: PayloadAction<Pick<IAuthFormState, 'isLoading'>>
		) => {
			state.isLoading = payload.isLoading
		},

		setAuthFormProtocol: (
			state,
			{ payload }: PayloadAction<Pick<IAuthFormState, 'protocol'>>
		) => {
			state.protocol = payload.protocol
		},

		setAuthFormProvider: (
			state,
			{ payload }: PayloadAction<{ provider?: string }>
		) => {
			state.provider = payload.provider
		},

		setAuthFormSSOUserId: (
			state,
			{ payload }: PayloadAction<{ ssoUserId?: string }>
		) => {
			state.ssoUserId = payload.ssoUserId
		},

		updateUserRequest: (
			state,
			{ payload }: PayloadAction<IUpdatePayload | IUpdateCheckPayload>
		) => {
			state.requestCompletedSuccess = false
			state.isLoading = true
		},

		otpVerifyRequest: (
			state,
			{ payload }: PayloadAction<Pick<IFormInputs, 'otpToken'>>
		) => {
			state.requestOtpVerifySuccess = false
			state.isLoading = true
		},

		otpVerifySuccess: (state) => {
			state.requestOtpVerifySuccess = true
			state.isLoading = false
		},

		otpVerifyFailure: (state) => {
			state.requestOtpVerifySuccess = false
			state.isLoading = false
		},

		otpValidateRequest: (
			state,
			{
				payload,
			}: PayloadAction<Pick<IFormInputs, 'otpToken'>>
		) => {
			state.requestOtpValidateSuccess = false
			state.requestCompletedSuccess = false
			state.isLoading = true
		},

		otpValidateSuccess: (state) => {
			state.requestOtpValidateSuccess = true
			state.requestCompletedSuccess = true
			state.isLoading = false
		},

		otpValidateFailure: (state) => {
			state.requestOtpValidateSuccess = false
			state.requestCompletedSuccess = false
			state.isLoading = false
		},

		otpGenerateRequest: (state) => {
			state.requestOtpGenerateSuccess = false
			state.requestCompletedSuccess = false
			state.isLoading = true
			state.otpGenerate = undefined
		},

		otpGenerateSuccess: (
			state,
			{ payload }: PayloadAction<IOtpGenerateResponse>
		) => {
			state.requestOtpGenerateSuccess = true
			state.isLoading = false
			state.otpGenerate = payload
		},

		otpGenerateFailure: (state) => {
			state.requestOtpGenerateSuccess = false
			state.isLoading = false
			state.otpGenerate = undefined
		},

		otpDisableRequest: (state) => {
			state.requestOtpDisableSuccess = false
			state.isLoading = true
		},

		otpDisableSuccess: (state) => {
			state.requestOtpDisableSuccess = true
			state.isLoading = false
		},

		otpDisableFailure: (state) => {
			state.requestOtpDisableSuccess = false
			state.isLoading = false
		},

		requestUpdateUserSuccess: (state) => {
			state.isLoading = false
			state.requestCompletedSuccess = true
		},

		signInRequest: (
			state,
			{ payload }: PayloadAction<Pick<IFormInputs, 'email' | 'password'>>
		) => {
			state.isLoading = true
			state.requestCompletedSuccess = false

			state.email = payload.email
			state.password = payload.password
		},

		setIsOtpRequired: (
			state,
			{ payload }: PayloadAction<Pick<IAuthFormState, 'isOtpRequired'>>
		) => {
			state.isOtpRequired = payload.isOtpRequired
		},
	},
})

export const {
	forgotPasswordRequest,
	setRequestCompletedSuccess,
	setRequestCompletedFailure,
	changePasswordRequest,
	changePasswordSuccess,
	changePasswordFailure,
	setAuthFormLoading,
	setAuthFormProtocol,
	setAuthFormProvider,
	setAuthFormSSOUserId,
	updateUserRequest,
	requestUpdateUserSuccess,
	signInRequest,
	setIsOtpRequired,
	otpVerifyRequest,
	otpVerifySuccess,
	otpVerifyFailure,
	otpValidateRequest,
	otpValidateSuccess,
	otpValidateFailure,
	otpGenerateRequest,
	otpGenerateSuccess,
	otpGenerateFailure,
	otpDisableRequest,
	otpDisableSuccess,
	otpDisableFailure,
} = authFormSlice.actions

export const { reducer } = authFormSlice
