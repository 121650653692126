import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IMainPageControllerState } from './types'

const initialState: IMainPageControllerState = {
	mainPage: null,
}

const mainPageControllerSlice = createSlice({
	name: 'mainPageController',
	initialState,
	reducers: {
		setMainPage: (state, { payload }: PayloadAction<string>) => {
			state.mainPage = payload
		},
	},
})

export const { setMainPage } = mainPageControllerSlice.actions

export const { reducer } = mainPageControllerSlice
