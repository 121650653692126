import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ICheckoutState, IInvoicesForCheckoutResponse } from './types'

const initialState: ICheckoutState = {
	isLoading: false,
	checkoutContractFeePercent: 0.1,
	checkoutInvoices: [],
}

const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		getInvoicesForCheckoutRequest: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			{ payload }: PayloadAction<string[]>
		) => {
			state.isLoading = true
			state.checkoutInvoices = []
			state.checkoutContract = undefined
		},

		getInvoicesForCheckoutSuccess: (
			state,
			{ payload }: PayloadAction<IInvoicesForCheckoutResponse>
		) => {
			state.checkoutInvoices = payload.invoices
			state.checkoutContract = payload.contract
			state.checkoutContractFeePercent = payload.feePercent
			state.isLoading = false
		},

		getInvoicesForCheckoutFailure: (state) => {
			state.isLoading = false
		},
	},
})

export const {
	getInvoicesForCheckoutRequest,
	getInvoicesForCheckoutSuccess,
	getInvoicesForCheckoutFailure,
} = checkoutSlice.actions

export const { reducer } = checkoutSlice
