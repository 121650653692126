import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { IUpdateInvoicesReadyToPay } from 'redux/invoice/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import {
	updateInvoicesReadyToPayReset,
	updateInvoicesReadyToPaySuccess,
} from '../reducer'

export function* updateInvoicesReadyToPaySaga({
	payload,
}: PayloadAction<IUpdateInvoicesReadyToPay>) {
	try {
		yield call(apiMiddleware, {
			url: app_config.services.updateInvoicesReadyToPay,
			method: 'POST',
			data: payload,
		})

		yield put(updateInvoicesReadyToPaySuccess())
	} catch (e) {
		yield put(updateInvoicesReadyToPayReset())
	}
}
