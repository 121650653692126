import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	CoinPriceSymbols,
	ICoinPrices,
	ICoinPricesState,
} from './types'

const initialState: ICoinPricesState = {
	isCoinPricesLoading: false,
	coinPrices: {
		[CoinPriceSymbols.ETH]: {
			usd: 0
		},
		[CoinPriceSymbols.NEAR]: {
			usd: 0
		},
		[CoinPriceSymbols.TRON]: {
			usd: 0
		},
		[CoinPriceSymbols.POLYGON]: {
			usd: 0
		},
		[CoinPriceSymbols.BSC]: {
			usd: 0
		},
		[CoinPriceSymbols.USDC]: {
			usd: 0
		},
		[CoinPriceSymbols.BUSD]: {
			usd: 0
		},
		[CoinPriceSymbols.GUSD]: {
			usd: 0
		},
		[CoinPriceSymbols.USDT]: {
			usd: 0
		},
	}
}

const coinPricesSlice = createSlice({
	name: 'coinPrices',
	initialState,
	reducers: {
		getCoinPricesRequest: (state) => {
			state.isCoinPricesLoading = true
		},
		getCoinPricesSuccess: (state, { payload: prices }: PayloadAction<ICoinPrices>) => {
			state.coinPrices = prices
			state.isCoinPricesLoading = false
		},
		getCoinPricesFailure: (state) => {
			state.coinPrices = {
				[CoinPriceSymbols.ETH]: {
					usd: 0
				},
				[CoinPriceSymbols.NEAR]: {
					usd: 0
				},
				[CoinPriceSymbols.TRON]: {
					usd: 0
				},
				[CoinPriceSymbols.POLYGON]: {
					usd: 0
				},
				[CoinPriceSymbols.BSC]: {
					usd: 0
				},
				[CoinPriceSymbols.USDC]: {
					usd: 0
				},
				[CoinPriceSymbols.BUSD]: {
					usd: 0
				},
				[CoinPriceSymbols.GUSD]: {
					usd: 0
				},
				[CoinPriceSymbols.USDT]: {
					usd: 0
				},
			}
			state.isCoinPricesLoading = false
		},
	},
})

export const {
	getCoinPricesRequest,
	getCoinPricesSuccess,
	getCoinPricesFailure,
} =
	coinPricesSlice.actions

export const { reducer } = coinPricesSlice
