import { createSlice } from '@reduxjs/toolkit'
import { ILogoutState } from './types'

const initialState: ILogoutState = {
	isLoading: false,
	isLogoutCompletedSuccess: false,
}

const logoutSlice = createSlice({
	name: 'logout',
	initialState,
	reducers: {
		logoutRequest: (state) => {
			state.isLogoutCompletedSuccess = false
			state.isLoading = true
		},

		logoutSuccess: (state) => {
			state.isLoading = false
			state.isLogoutCompletedSuccess = true
		},

		logoutFailure: (state) => {
			state.isLoading = false
			state.isLogoutCompletedSuccess = false
		},
	},
})

export const { logoutRequest, logoutSuccess, logoutFailure } =
	logoutSlice.actions

export const { reducer } = logoutSlice
