import { call, put, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { ITriggerTeamMemberRoleRequest } from '../types'
import {
	triggerTeamMemberRoleSuccess,
	triggerTeamMemberRoleFailure,
} from '../reducer'
import { IStore } from '../../types'
import { setUserTeamRoles } from '../../user/reducer'

export function* triggerTeamMemberRoleSaga({
	payload,
}: PayloadAction<ITriggerTeamMemberRoleRequest>) {
	try {
		const { user } = yield select(
			(state: IStore) => state.user
		)
		const { memberRolesList } = yield select(
			(state: IStore) => state.memberList
		)

		yield call(apiMiddleware, {
			url: app_config.services.triggerTeamMemberRole,
			data: payload,
			method: 'POST',
		})

		if (user.id === payload.userId) {
			yield put(
				setUserTeamRoles(memberRolesList[payload.userId])
			)
		}

		yield put(triggerTeamMemberRoleSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(triggerTeamMemberRoleFailure())
	}
}
