/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITeamRoleUser } from 'redux/user/types'
import {
	IDeleteTeamMemberRequest,
	IInviteMemberRequest,
	IMemberListState, IMemberRolesList,
	ITriggerTeamMemberRoleRequest,
} from './types'

interface IInviteMemberModal {
	isOpened: boolean
}

const initialState: IMemberListState = {
	memberList: [],
	memberRolesList: {},
	isLoading: true,
	isInviteMemberModalOpened: false,
	isInviteMemberLoading: false,
	isInviteMemberSuccess: false,
	isTriggerTeamMemberRoleLoading: false,
	isTriggerTeamMemberRoleSuccess: false,
	isDeleteTeamMemberSuccess: false,
}

const memberListSlice = createSlice({
	name: 'memberList',
	initialState,
	reducers: {
		setInviteMemberModalOpened: (
			state,
			{ payload: { isOpened } }: PayloadAction<IInviteMemberModal>
		) => {
			state.isInviteMemberModalOpened = isOpened
		},

		inviteMembersRequest: (
			state,
			{ payload }: PayloadAction<IInviteMemberRequest>
		) => {
			state.isInviteMemberLoading = true
			state.isInviteMemberSuccess = false
		},

		inviteMembersSuccess: (state) => {
			state.isInviteMemberLoading = false
			state.isInviteMemberSuccess = true
		},

		inviteMembersFailure: (state) => {
			state.isInviteMemberLoading = false
			state.isInviteMemberSuccess = false
		},

		getMembersRequest: (state) => {
			state.isLoading = true
		},

		getMembersWithoutLoadingRequest: () => {},

		getMembersSuccess: (state, { payload }: PayloadAction<ITeamRoleUser[]>) => {
			const memberRolesList: IMemberRolesList = {}
			payload.forEach(user => {
				memberRolesList[user.id] = user.team?.roles ?? []
			})

			state.memberRolesList = memberRolesList
			state.memberList = payload

			state.isLoading = false
		},

		getMembersFailure: (state) => {
			state.isLoading = false
		},

		triggerTeamMemberRoleRequest: (state, { payload: { userId, role, isAdding } }: PayloadAction<ITriggerTeamMemberRoleRequest>) => {
			state.isTriggerTeamMemberRoleLoading = true
			state.isTriggerTeamMemberRoleSuccess = false

			const memberRolesList = { ...state.memberRolesList }
			if (isAdding) {
				memberRolesList[userId].push(role)
			} else {
				memberRolesList[userId] = memberRolesList[userId].filter(userRole => userRole !== role)
			}

			state.memberRolesList = memberRolesList
		},

		triggerTeamMemberRoleSuccess: (state) => {
			state.isTriggerTeamMemberRoleLoading = false
			state.isTriggerTeamMemberRoleSuccess = true
		},

		triggerTeamMemberRoleFailure: (state) => {
			state.isTriggerTeamMemberRoleLoading = false
			state.isTriggerTeamMemberRoleSuccess = false
		},

		deleteTeamMemberRequest: (state, { payload }: PayloadAction<IDeleteTeamMemberRequest>) => {
			state.isDeleteTeamMemberSuccess = false
		},

		deleteTeamMemberSuccess: (state) => {
			state.isDeleteTeamMemberSuccess = true
		},

		deleteTeamMemberFailure: (state) => {
			state.isDeleteTeamMemberSuccess = false
		},
	},
})

export const {
	setInviteMemberModalOpened,

	inviteMembersRequest,
	inviteMembersSuccess,
	inviteMembersFailure,

	getMembersRequest,
	getMembersSuccess,
	getMembersFailure,

	getMembersWithoutLoadingRequest,

	triggerTeamMemberRoleRequest,
	triggerTeamMemberRoleSuccess,
	triggerTeamMemberRoleFailure,

	deleteTeamMemberRequest,
	deleteTeamMemberSuccess,
	deleteTeamMemberFailure,
} = memberListSlice.actions

export const { reducer } = memberListSlice
