import { call, put } from 'redux-saga/effects'
import { IOtpGenerateResponse } from 'redux/authForm/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { otpGenerateFailure, otpGenerateSuccess } from 'redux/authForm/reducer'

export function* otpGenerateSaga() {
	try {
		const response: {
			message: string
			data: IOtpGenerateResponse
		} = yield call(apiMiddleware, {
			url: app_config.services.otpGenerate,
			method: 'POST',
		})

		yield put(otpGenerateSuccess(response.data))
	} catch (e) {
		yield put(otpGenerateFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
