import { AUTH } from 'constants/localStorageKeys'
import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'

import { IFormInputs } from 'components/SignInUpForm'
import { onOpenNotification } from 'redux/notification/reducer'
import {
	setAuthFormLoading,
	setIsOtpRequired,
	setRequestCompletedSuccess,
} from 'redux/authForm/reducer'

export function* signInSaga({
	payload,
}: PayloadAction<Pick<IFormInputs, 'email' | 'password'>>) {
	try {
		const response: {
			access_token?: string
			message: string
			is_otp_required: boolean
		} = yield call(authMiddleware, {
			url: app_config.services.signIn,
			data: payload,
		})

		yield put(setIsOtpRequired({ isOtpRequired: response.is_otp_required }))
		yield put(setRequestCompletedSuccess())

		if (!response.is_otp_required && response.access_token) {
			localStorage.setItem(AUTH, response.access_token)
		} else {
			yield put(
				onOpenNotification({
					message: response.message,
					notificationType: 'info',
				})
			)
		}
	} catch (e) {
		const error = e as { message: string }

		yield put(setAuthFormLoading({ isLoading: false }))

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
