import { ITag } from 'redux/tag/types'
import { ITeam, IUser } from 'redux/user/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

export type IStatusType = 'draft' | 'pending' | 'readyToPay' | 'paid' | 'closed' | 'void' | 'inReview'

export enum ProtocolEnumType {
	NOT_DEFINED = '',
	NEAR = 'near',
	ETH = 'eth',
	TRON = 'tron',
	BSC = 'bsc',
	POLYGON = 'polygon',
}

export enum OperationEnumType {
	PAYMENT = 'payment',
	WITHDRAWAL = 'withdrawal',
	REFUND = 'void',
}

export enum ContractEnumType {
	NEAR = 'v0.geekpay.near',
	NEAR_TEST = 'geekpayv0.testnet',
	ETH_TEST = '0x2a56AD5A053A93DbD692c58B49E24d0808bE33a8',
	ETH = '0xCD5e5071B41b9350b6914c1E73A1Ab8Fe3Cf7914',
	TRON_TEST = 'TLTm6qELmzC3muKu7z28VTHz8EneaBFKW7',
	TRON = 'TGtaryie52tRfP8HoWFaRiqfbdnDKEk9wN',
	BSC = '0xe5ee72ccc0506400c1f1e4c1fb873ba483b3656a',
	POLYGON = '0x9E5853e915Fb4E9987D228AaA129ed512345E447',
}

export enum WalletEnumType {
	UNKNOWN_WALLET = 'Unknown Wallet',
	NEAR_WALLET = 'Near Wallet',
	SENDER_WALLET = 'Sender Wallet',
	LEDGER_WALLET = 'Ledger Wallet',
	METAMASK_WALLET = 'MetaMask Wallet',
	BINANCE_WALLET = 'Binance Wallet',
	TRONLINK_WALLET = 'TronLink Wallet',
}

export interface IInvoiceState {
	isInvoiceLoading: boolean
	isInvoiceReviewsLoading: boolean
	isInvoiceChangelogsLoading: boolean
	isReviewInvoicesLoading: boolean
	isUpdateInvoicesReadyToPayLoading: boolean
	isUpdateInvoicesReadyToPaySuccess: boolean
	isDeleteInvoicesRequestSuccess: boolean
	isDownloadInvoicePdfInProgress: boolean
	isUpdateInvoiceStatusLoading: boolean
	isUpdateInvoiceStatusComplete: boolean
	isCloneInvoicesRequestSuccess: boolean
	isArchiveInvoicesRequestSuccess: boolean
	isReviewInvoicesRequestSuccess: boolean
	isCreateInvoiceModalOpened: boolean
	isUpdateInvoicesAccountLoading: boolean
	isUpdateInvoicesAccountSuccess: boolean
	lastModifiedInvoiceId?: string
	currentInvoice: IInvoice
	currentInvoiceUserReview?: IInvoiceUserReview
	currentInvoiceReviews: IInvoiceReview[]
	currentInvoiceChangelogs: IInvoiceChangelog[]
	currentReviewInvoiceIds: string[]
}

export interface IInvoiceUser {
	id: string
	avatar?: string
	name?: string
	surname?: string
	email?: string
	address?: string
	role?: string
	team?: ITeam
	protocol?: ProtocolEnumType
	account_id?: string
}

export interface ITransactionDetails {
	hash: string
	operation: string
	token?: WalletAccountBalanceToken
	total_amount?: number
	created_at: string
}

interface IInvoiceDocument {
	id: number | string | undefined;
	name: string;
	type: string;
	size: number;
}

export interface IInvoice {
	id: string
	invoice_id?: number | null
	custom_invoice_id?: string | null
	author?: string
	contract: ContractEnumType | null
	receiver: IInvoiceUser
	receiver_account_id?: string | null
	sender?: IInvoiceUser
	sender_account_id?: string | null
	team?: ITeam | null
	tokens: {
		[key in WalletAccountBalanceToken]?: {
			units: IInvoiceRowData[]
		}
	}
	transactions?: ITransactionDetails[]
	tags?: ITag[]
	documents?: IInvoiceDocument[]
	comment?: string
	status: IStatusType
	token: WalletAccountBalanceToken
	total_amount: number
	token_price: number
	created_on_blockchain?: boolean
	created_at?: string
	updated_at?: string
	archived_for_team?: boolean
	archived_for_receiver?: boolean
}

interface IInvoiceRowData {
	description: string
	qty: string
	unitPrice: string
	amount: number
}

export interface IUpdateInvoiceFunc {
	status: IStatusType
}

export interface IInvoiceStatus {
	status: IStatusType
}

export interface IUpdateInvoiceTransactionDetails {
	hash: null | string
	account: null | string
	contract: ContractEnumType
	operation: OperationEnumType
	protocol: ProtocolEnumType
	wallet: WalletEnumType
	token: WalletAccountBalanceToken
	amount: number
	fee: number
	gasUsed: number
	gasPrice: number
}

export interface IUpdateInvoiceStatus extends IInvoiceStatus {
	invoiceId: string
	transactionDetails?: IUpdateInvoiceTransactionDetails
}

export interface IUpdateInvoicesReadyToPay {
	invoiceIds: string[]
	tokenPrice: number
	transactionDetails?: IUpdateInvoiceTransactionDetails[]
}

export enum InvoiceReviewStatus {
	APPROVED = 'approved',
	REJECTED = 'rejected',
	AWAITING = 'awaiting',
}

export interface IInvoiceReview {
	reviewer: IUser
	status: InvoiceReviewStatus
	comment: null | string
}

export enum InvoiceChangelogType {
	CREATED = 'created',
	UPDATED = 'updated',
	REVIEWED = 'reviewed',
	COMMENTED = 'commented',
}

export interface IInvoiceChangelog {
	type: InvoiceChangelogType
	status: IStatusType
	user: IUser
	reviewStatus: InvoiceReviewStatus | null
	comment: null | string
	created_at: string
}

export interface IInvoiceUserReview {
	isApproved: boolean
	isRejected: boolean
}

export interface IInvoicesUserReviews {
	approvedIds: string[]
	rejectedIds: string[]
}

export interface ICreateInvoiceModal {
	isOpened: boolean
}

export interface IInvoiceDocumentRequest {
	id: string
	invoiceId: string
	name?: string
	callback?: (documentId: string) => void
}

export interface IUpdateInvoicesAccount {
	invoiceIds: string[]
	accountId: string
}
