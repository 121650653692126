import { call, put } from 'redux-saga/effects'
import authMiddleware from 'services/auth_middleware'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import {
	changePasswordFailure,
	changePasswordSuccess,
	setAuthFormLoading,
} from 'redux/authForm/reducer'
import { IChangePassword } from '../types'

export function* changePasswordSaga({
	payload,
}: PayloadAction<IChangePassword>) {
	try {
		yield put(setAuthFormLoading({ isLoading: true }))

		const isAuth = !!payload.currentPassword

		const { message }: { message: string } = yield call(
			isAuth ? apiMiddleware : authMiddleware,
			{
				url: isAuth
					? app_config.services.userChangePassword
					: app_config.services.changePassword,
				data: payload,
				method: 'POST',
			}
		)

		yield put(changePasswordSuccess())

		yield put(onOpenNotification({ message, notificationType: 'success' }))
	} catch (e) {
		yield put(changePasswordFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
