import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import {
	reviewInvoicesRequestSuccess,
	reviewInvoicesRequestComplete,
} from '../reducer'
import { InvoiceReviewStatus } from '../types'

export function* reviewInvoicesSaga({
	payload,
}: PayloadAction<{ invoiceIds: string[], status: InvoiceReviewStatus, comment?: string }>) {
	try {
		const { message } = yield call(apiMiddleware, {
			url: app_config.services.reviewInvoice,
			method: 'POST',
			data: payload,
		})

		yield put(reviewInvoicesRequestSuccess())

		yield put(
			onOpenNotification({
				message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		yield put(reviewInvoicesRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
