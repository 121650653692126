import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoice } from '../types'

import { invoiceRequestComplete, invoiceRequestSuccess } from '../reducer'

export function* createInvoiceSaga({
	payload,
}: PayloadAction<{ invoices: IInvoice[], usePreviousInvoiceToken?: boolean }>) {
	try {
		const {
			data,
		}: {
			data: {
				invoices: IInvoice[]
			}
		} = yield call(apiMiddleware, {
			url: app_config.services.createInvoice,
			method: 'POST',
			data: {
				...payload,
			},
		})

		yield put(invoiceRequestSuccess(data.invoices[0]))
	} catch (e) {
		yield put(invoiceRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
