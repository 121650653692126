import { call, put } from 'redux-saga/effects'

import authMiddleware from 'services/auth_middleware'
import { ICoinPrices } from './types'
import { getCoinPricesFailure, getCoinPricesSuccess } from './reducer'

export function* getCoinPricesSaga() {
	try {
		const prices: ICoinPrices = yield call(authMiddleware, {
			url: 'https://api.coingecko.com/api/v3/simple/price?ids=near%2Cethereum%2Ctron%2Ctether%2Cusd-coin%2Cbinance-usd%2Cgemini-dollar%2Cmatic-network%2Cbinancecoin&vs_currencies=usd',
			method: 'GET',
			withCredentials: false
		})

		yield put(getCoinPricesSuccess(prices))
	} catch (e) {
		yield put(getCoinPricesFailure())
	}
}
