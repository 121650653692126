/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMyUserRequest, IMyUserState } from 'redux/myUser/types'
import { ProtocolEnumType } from 'redux/invoice/types'
import { ITeamRoleUser } from 'redux/user/types'

const initialState: IMyUserState = {
	isMyUserLoading: true,
	isDeleteMyUserSuccess: false,

	myUser: {
		account_id: '',
		created_at: '',
		email: '',
		address: '',
		surname: '',
		id: '',
		name: '',
		role: '',
		protocol: ProtocolEnumType.NEAR,
		uid: '',
		updated_at: '',
		otp_verified: false,
		otp_enabled: false,
		is_welcome_discarded: false,
		invoices: [],
		team: {
			id: '',
			roles: [],
		},
		team_role_id: '',
	},
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getMyUserRequest: (state, { payload }: PayloadAction<IMyUserRequest>) => {
			state.isMyUserLoading = true
		},
		getMyUserSuccess: (state, { payload }: PayloadAction<ITeamRoleUser>) => {
			state.myUser = payload
			state.isMyUserLoading = false
		},
		getMyUserFailure: (state) => {
			state.isMyUserLoading = false
		},

		deleteMyUserRequest: (state, { payload }: PayloadAction<IMyUserRequest>) => {
			state.isDeleteMyUserSuccess = false
		},
		deleteMyUserSuccess: (state) => {
			state.isDeleteMyUserSuccess = true
		},
		deleteMyUserFailure: (state) => {
			state.isDeleteMyUserSuccess = false
		},
	},
})

export const {
	getMyUserRequest,
	getMyUserSuccess,
	getMyUserFailure,

	deleteMyUserRequest,
	deleteMyUserSuccess,
	deleteMyUserFailure,
} = userSlice.actions

export const { reducer } = userSlice
