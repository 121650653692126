import { call, put } from 'redux-saga/effects'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { ITeamRoleUser } from 'redux/user/types'

import { onOpenNotification } from 'redux/notification/reducer'
import { getMembersFailure, getMembersSuccess } from '../reducer'

export function* getMembersSaga() {
	try {
		const memberList: {
			data: { users: ITeamRoleUser[] }
		} = yield call(apiMiddleware, {
			url: app_config.services.getMembers,
		})

		yield put(getMembersSuccess(memberList.data.users))
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(getMembersFailure())
	}
}
