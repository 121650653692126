import { IFormInputs } from 'components/SignInUpForm'
import { AUTH } from 'constants/localStorageKeys'
import { call, put, select } from 'redux-saga/effects'
import { IStore } from 'redux/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import { otpValidateFailure, otpValidateSuccess } from 'redux/authForm/reducer'

export function* otpValidateSaga({
	payload,
}: PayloadAction<Pick<IFormInputs, 'otpToken'>>) {
	const { provider, ssoUserId, email, password } = yield select(
		(state: IStore) => state.authForm
	)

	try {
		const response: {
			message: string
			access_token: string
		} = yield call(apiMiddleware, {
			url: app_config.services.otpValidate,
			data: {
				...payload,
				email,
				password,
				provider,
				ssoUserId,
			},
			method: 'POST',
		})

		localStorage.setItem(AUTH, response.access_token)

		yield put(otpValidateSuccess())
	} catch (e) {
		yield put(otpValidateFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
