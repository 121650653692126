/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProtocolEnumType } from 'redux/invoice/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import { IInvoiceReportsState } from './types'

const fromDate = new Date()
fromDate.setMonth(fromDate.getMonth() - 1)

const initialState: IInvoiceReportsState = {
	invoiceReports: [],
	isFirstLoading: true,
	isLoading: false,
	isGenerateCsvLoading: false,
	generateCsv: false,
	filters: {
		sortBy: '',
		direction: '',
		page: 0,
		token: WalletAccountBalanceToken.NOT_DEFINED,
		protocol: ProtocolEnumType.NEAR,
		dateRange: null,
		tags: [],
	},
	total: {
		amounts: [],
		count: 0,
	},
}

const invoiceReportsSlice = createSlice({
	name: 'invoiceReports',
	initialState,
	reducers: {
		getInvoiceReportsRequest: (
			state,
			{
				payload,
			}: PayloadAction<
				Pick<IInvoiceReportsState, 'filters' | 'generateCsv' | 'isFirstLoading'>
			>
		) => {
			state.isLoading = !payload.generateCsv
			state.isGenerateCsvLoading = !!payload.generateCsv
			state.isFirstLoading = payload.isFirstLoading ?? false
		},

		getInvoiceReportsSuccess: (
			state,
			{
				payload,
			}: PayloadAction<
				Pick<IInvoiceReportsState, 'invoiceReports' | 'filters' | 'total'>
			>
		) => {
			state.isLoading = false
			state.isFirstLoading = false
			state.invoiceReports = payload.invoiceReports
			state.total = payload.total
			state.filters = payload.filters
		},

		getInvoiceReportsGenerateCsv: (state) => {
			state.isGenerateCsvLoading = false
		},

		getInvoiceReportsFailure: (state) => {
			state.isLoading = false
			state.isGenerateCsvLoading = false
		},

		resetInvoiceReportsFilters: (state) => {
			state.filters = {
				sortBy: '',
				direction: '',
				page: 0,
				token: WalletAccountBalanceToken.NOT_DEFINED,
				protocol: ProtocolEnumType.NEAR,
				dateRange: null,
				tags: [],
			}
		},
	},
})

export const {
	getInvoiceReportsRequest,
	getInvoiceReportsSuccess,
	getInvoiceReportsGenerateCsv,
	getInvoiceReportsFailure,

	resetInvoiceReportsFilters,
} = invoiceReportsSlice.actions

export const { reducer } = invoiceReportsSlice
