import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { IInvoice, IUpdateInvoiceStatus } from 'redux/invoice/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import {
	invoiceRequestComplete,
	invoiceRequestSuccess,
	updateInvoiceStatusRequestComplete,
} from '../reducer'

export function* updateInvoiceStatusSaga({
	payload,
}: PayloadAction<IUpdateInvoiceStatus>) {
	try {
		const {
			data,
		}: {
			data: {
				invoice: IInvoice
			}
		} = yield call(apiMiddleware, {
			url: app_config.services.updateInvoice,
			method: 'POST',
			data: {
				id: payload.invoiceId,
				status: payload.status,
				transaction: payload.transactionDetails,
			},
		})

		yield put(invoiceRequestSuccess(data.invoice))
		yield put(updateInvoiceStatusRequestComplete())
	} catch (e) {
		yield put(invoiceRequestComplete())
		yield put(updateInvoiceStatusRequestComplete())
	}
}
