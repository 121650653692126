import { AUTH } from 'constants/localStorageKeys'
import { call, put } from 'redux-saga/effects'
import { ProtocolEnumType } from 'redux/invoice/types'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import {
	requestUpdateUserSuccess,
	setAuthFormLoading,
	setRequestCompletedFailure,
} from 'redux/authForm/reducer'
import { IUpdateCheckPayload, IUpdatePayload } from '../types'

export function* updateUserSaga({
	payload,
}: PayloadAction<IUpdatePayload | IUpdateCheckPayload>) {
	try {
		yield put(setAuthFormLoading({ isLoading: true }))

		const response: {
			message: string
			access_token: string
			protocol?: ProtocolEnumType
		} = yield call(
			authMiddleware,
			{
				url: app_config.services.updateUser,
				data: payload,
				method: 'POST',
			}
		)

		if (response.access_token) {
			localStorage.setItem(AUTH, response.access_token)
		}

		yield put(requestUpdateUserSuccess())

		if (response.message) {
			yield put(
				onOpenNotification({
					message: response.message,
					notificationType: 'success',
				})
			)
		}
	} catch (e) {
		yield put(setRequestCompletedFailure())

		if (!localStorage.getItem(AUTH)) {
			const error = e as { message: string }

			yield put(
				onOpenNotification({
					message: error.message,
					notificationType: 'error',
				})
			)
		}
	}
}
