import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'

import {
	updateInvoicesAccountSuccess,
	updateInvoicesAccountReset,
} from '../reducer'
import { IUpdateInvoicesAccount } from '../types'

export function* updateInvoicesAccountSaga({
	payload,
}: PayloadAction<IUpdateInvoicesAccount>) {
	try {
		yield call(apiMiddleware, {
			url: app_config.services.updateInvoicesAccount,
			method: 'POST',
			data: payload,
		})

		yield put(updateInvoicesAccountSuccess())
	} catch (e) {
		yield put(updateInvoicesAccountReset())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
