import fileDownload from 'js-file-download'
import { call, put, select } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'

import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { IStore } from 'redux/types'
import { onOpenNotification } from 'redux/notification/reducer'

import {
	getInvoiceReportsFailure,
	getInvoiceReportsGenerateCsv,
	getInvoiceReportsSuccess,
} from '../reducer'
import {
	IInvoiceReport,
	IInvoiceReportAmount,
	IInvoiceReportsState,
} from '../types'

interface IInvoiceReportResponse {
	transactions: IInvoiceReport[]
	total: {
		amounts: IInvoiceReportAmount[]
		count: number
	}
}

export function* getInvoiceReportsSaga({
	payload,
}: PayloadAction<Pick<IInvoiceReportsState, 'filters' | 'generateCsv'>>) {
	try {
		const { filters, invoiceReports } = yield select(
			(state: IStore) => state.invoiceReports
		)

		const body = {
			...filters,
			...payload.filters,
			page: payload.filters?.page ?? 0,
		}

		const data = {
			...body,
			generateCsv: payload.generateCsv,
		}

		const response: IInvoiceReportResponse = yield call(apiMiddleware, {
			url: app_config.services.getInvoiceReports,
			method: 'POST',
			data,
			responseType: payload.generateCsv ? 'blob' : 'json',
		})

		if (payload.generateCsv) {
			fileDownload(
				response as unknown as ArrayBuffer,
				'report.csv',
				'application/csv'
			)

			yield put(getInvoiceReportsGenerateCsv())
		} else {
			yield put(
				getInvoiceReportsSuccess({
					invoiceReports: data.page
						? [...invoiceReports, ...response.transactions]
						: response.transactions,
					filters: body,
					total: response.total,
				})
			)
		}
	} catch (e) {
		const error = e as { message: string }

		yield put(getInvoiceReportsFailure())

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
