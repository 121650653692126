import { put } from 'redux-saga/effects'

import { onOpenNotification } from 'redux/notification/reducer'
import { setRequestCompletedFailure } from 'redux/authForm/reducer'
import { logoutFailure, logoutSuccess } from '../reducer'

export function* logoutSaga() {
	try {
		yield put(logoutSuccess())
	} catch (e) {
		yield put(setRequestCompletedFailure())

		const error = e as { message: string }

		yield put(logoutFailure())

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
