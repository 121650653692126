/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import app_config from 'config/app_config'
import { IInvoiceTableRowData } from 'pages/invoice/components/InvoiceTable/InvoiceTableRow'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import {
	ICreateInvoiceModal, IInvoiceDocumentRequest,
	IInvoice, IInvoiceChangelog, IInvoiceReview,
	IInvoiceState, IInvoiceUserReview, InvoiceReviewStatus,
	IUpdateInvoiceFunc,
	IUpdateInvoicesReadyToPay,
	IUpdateInvoiceStatus, IUpdateInvoicesAccount,
} from './types'

export const defaultRowData: IInvoiceTableRowData = {
	description: '',
	qty: '1',
	unitPrice: '0',
	amount: 0,
}

const initialState: IInvoiceState = {
	isInvoiceLoading: false,
	isInvoiceReviewsLoading: false,
	isInvoiceChangelogsLoading: false,
	isReviewInvoicesLoading: false,
	isUpdateInvoicesReadyToPayLoading: false,
	isUpdateInvoicesReadyToPaySuccess: false,
	isDeleteInvoicesRequestSuccess: false,
	isDownloadInvoicePdfInProgress: false,
	isUpdateInvoiceStatusLoading: false,
	isUpdateInvoiceStatusComplete: false,
	isCloneInvoicesRequestSuccess: false,
	isArchiveInvoicesRequestSuccess: false,
	isReviewInvoicesRequestSuccess: false,
	isCreateInvoiceModalOpened: false,
	isUpdateInvoicesAccountLoading: false,
	isUpdateInvoicesAccountSuccess: false,
	lastModifiedInvoiceId: undefined,
	currentInvoiceUserReview: undefined,
	currentInvoice: {
		id: '',
		author: '',
		invoice_id: null,
		contract: null,
		receiver: { id: '' },
		sender: { id: '' },
		tokens: {
			[app_config.env.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken]: {
				units: [
					{
						...defaultRowData,
					},
				],
			},
		},
		token: app_config.env
			.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken,
		transactions: [],
		tags: [],
		documents: [],
		comment: '',
		status: 'draft',
		total_amount: 0,
		token_price: 0,
		created_at: '',
	},
	currentInvoiceReviews: [],
	currentInvoiceChangelogs: [],
	currentReviewInvoiceIds: [],
}

const invoiceSlice = createSlice({
	name: 'invoice',
	initialState,
	reducers: {
		getInvoiceRequest: (
			state,
			{ payload }: PayloadAction<{ id: string; userId?: string }>
		) => {
			state.isInvoiceLoading = true
		},

		createInvoiceRequest: (
			state,
			{ payload }: PayloadAction<{ invoices: IInvoice[], usePreviousInvoiceToken?: boolean }>
		) => {
			state.isInvoiceLoading = true
		},

		updateInvoiceRequest: (
			state,
			{ payload }: PayloadAction<IUpdateInvoiceFunc>
		) => {
			state.isInvoiceLoading = true
		},

		updateInvoiceStatusRequest: (
			state,
			{ payload }: PayloadAction<IUpdateInvoiceStatus>
		) => {
			state.isUpdateInvoiceStatusLoading = true
			state.isUpdateInvoiceStatusComplete = false
		},

		updateInvoiceStatusRequestComplete: (state) => {
			state.isUpdateInvoiceStatusComplete = true
			state.isUpdateInvoiceStatusLoading = false
		},

		updateInvoicesReadyToPayRequest: (
			state,
			{ payload }: PayloadAction<IUpdateInvoicesReadyToPay>
		) => {
			state.isUpdateInvoicesReadyToPayLoading = true
			state.isUpdateInvoicesReadyToPaySuccess = false
		},

		updateInvoicesReadyToPaySuccess: (state) => {
			state.isUpdateInvoicesReadyToPayLoading = false
			state.isUpdateInvoicesReadyToPaySuccess = true
		},

		updateInvoicesReadyToPayReset: (state) => {
			state.isUpdateInvoicesReadyToPayLoading = false
			state.isUpdateInvoicesReadyToPaySuccess = false
		},

		deleteInvoicesRequest: (
			state,
			{ payload }: PayloadAction<{ invoiceIds: string[] }>
		) => {
			state.isInvoiceLoading = true
			state.isDeleteInvoicesRequestSuccess = false
		},

		deleteInvoicesRequestSuccess: (state) => {
			state.isInvoiceLoading = false
			state.isDeleteInvoicesRequestSuccess = true
		},

		deleteInvoicesRequestFailure: (state) => {
			state.isInvoiceLoading = false
			state.isDeleteInvoicesRequestSuccess = false
		},

		cloneInvoicesRequest: (
			state,
			{ payload }: PayloadAction<{ invoiceIds: string[] }>
		) => {
			state.isInvoiceLoading = true
			state.isCloneInvoicesRequestSuccess = false
		},

		cloneInvoicesRequestSuccess: (state) => {
			state.isCloneInvoicesRequestSuccess = true
			state.isInvoiceLoading = false
		},

		archiveInvoicesRequest: (
			state,
			{ payload }: PayloadAction<{ invoiceIds: string[], archive: boolean }>
		) => {
			state.isInvoiceLoading = true
			state.isArchiveInvoicesRequestSuccess = false
		},

		archiveInvoicesRequestSuccess: (state) => {
			state.isCloneInvoicesRequestSuccess = true
			state.isInvoiceLoading = false
		},

		reviewInvoicesRequest: (
			state,
			{ payload }: PayloadAction<{ invoiceIds: string[], status: InvoiceReviewStatus, comment?: string }>
		) => {
			state.currentReviewInvoiceIds = [ ...state.currentReviewInvoiceIds, ...payload.invoiceIds]
			state.isReviewInvoicesLoading = true
			state.isReviewInvoicesRequestSuccess = false
		},

		reviewInvoicesRequestSuccess: (state) => {
			state.currentReviewInvoiceIds = []
			state.isReviewInvoicesRequestSuccess = true
			state.isReviewInvoicesLoading = false
		},

		reviewInvoicesRequestComplete: (state) => {
			state.currentReviewInvoiceIds = []
			state.isReviewInvoicesLoading = false
		},

		invoiceRequestSuccess: (state, { payload }: PayloadAction<IInvoice>) => {
			state.currentInvoice = payload
			state.isInvoiceLoading = false
		},

		invoiceRequestComplete: (state) => {
			state.isInvoiceLoading = false
		},

		getInvoiceReviewsRequest: (state, { payload }: PayloadAction<{ id: string }>) => {
			state.isInvoiceReviewsLoading = true
		},

		invoiceReviewsRequestSuccess: (state, { payload }: PayloadAction<IInvoiceReview[]>) => {
			state.currentInvoiceReviews = payload
			state.isInvoiceReviewsLoading = false
		},

		invoiceReviewsRequestFailure: (state) => {
			state.currentInvoiceReviews = []
			state.isInvoiceReviewsLoading = false
		},

		getInvoiceChangelogsRequest: (state, { payload }: PayloadAction<{ id: string }>) => {
			state.isInvoiceChangelogsLoading = true
		},

		invoiceChangelogsRequestSuccess: (state, { payload }: PayloadAction<IInvoiceChangelog[]>) => {
			state.currentInvoiceChangelogs = payload
			state.isInvoiceChangelogsLoading = false
		},

		invoiceChangelogsRequestFailure: (state) => {
			state.currentInvoiceChangelogs = []
			state.isInvoiceChangelogsLoading = false
		},

		setResetInvoiceData: (state) => {
			state.isInvoiceLoading = false
			state.isInvoiceReviewsLoading = false
			state.isInvoiceChangelogsLoading = false
			state.isUpdateInvoiceStatusLoading = false
			state.isReviewInvoicesLoading = false
			state.isUpdateInvoiceStatusLoading = false
			state.isDeleteInvoicesRequestSuccess = false
			state.isDownloadInvoicePdfInProgress = false
			state.isUpdateInvoiceStatusComplete = false
			state.isCloneInvoicesRequestSuccess = false
			state.isReviewInvoicesRequestSuccess = false

			state.currentInvoice = {
				id: '',
				invoice_id: null,
				custom_invoice_id: null,
				contract: null,
				receiver: { id: '' },
				sender: { id: '' },
				tokens: {
					[app_config.env.REACT_APP_NEAR_USDTT_TOKEN]: {
						units: [
							{
								...defaultRowData,
							},
						],
					},
				},
				token: app_config.env
					.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken,
				transactions: [],
				tags: [],
				comment: '',
				status: 'draft',
				total_amount: 0,
				token_price: 0,
				created_at: '',
			}
			state.currentInvoiceReviews = []
			state.currentInvoiceChangelogs = []
			state.currentInvoiceUserReview = undefined
		},

		setCurrentInvoiceToken: (
			state,
			{ payload }: PayloadAction<WalletAccountBalanceToken>
		) => {
			state.currentInvoice.tokens = {
				[payload]: {
					...state.currentInvoice.tokens[state.currentInvoice.token],
				},
			}

			state.currentInvoice.token = payload
		},

		setCurrentInvoiceTotalAmount: (
			state,
			{ payload: { total } }: PayloadAction<{ total: number }>
		) => {
			state.currentInvoice.total_amount = total
		},

		setCurrentInvoiceTokens: (
			state,
			{ payload: { units } }: PayloadAction<{ units: IInvoiceTableRowData[] }>
		) => {
			state.currentInvoice.tokens = {
				[state.currentInvoice.token]: {
					units: [...units],
				},
			}
		},

		setInvoiceComment: (state, { payload }: PayloadAction<string>) => {
			state.currentInvoice.comment = payload
		},

		setInvoiceCustomId: (state, { payload }: PayloadAction<string | null>) => {
			state.currentInvoice.custom_invoice_id = payload
		},

		downloadInvoicePdfRequest: (
			state,
			{ payload }: PayloadAction<Pick<IInvoice, 'id' | 'invoice_id' | 'status'>>
		) => {
			state.isDownloadInvoicePdfInProgress = true
		},

		downloadInvoicePdfRequestComplete: (state) => {
			state.isDownloadInvoicePdfInProgress = false
		},

		setCurrentInvoiceUserReview: (state, { payload }: PayloadAction<IInvoiceUserReview | undefined>) => {
			state.currentInvoiceUserReview = payload
		},

		setCurrentInvoiceReceiverAccountId: (
			state,
			{ payload }: PayloadAction<IInvoice['receiver_account_id']>
		) => {
			state.currentInvoice.receiver_account_id = payload
		},

		setCreateInvoiceModalOpened: (
			state,
			{ payload: { isOpened } }: PayloadAction<ICreateInvoiceModal>
		) => {
			state.isCreateInvoiceModalOpened = isOpened
		},

		deleteInvoiceDocumentRequest: (
			state,
			{ payload }: PayloadAction<IInvoiceDocumentRequest>
		) => {},

		downloadInvoiceDocumentRequest: (
			state,
			{ payload }: PayloadAction<IInvoiceDocumentRequest>
		) => {},

		updateInvoicesAccountRequest: (
			state,
			{ payload }: PayloadAction<IUpdateInvoicesAccount>
		) => {
			state.isUpdateInvoicesAccountLoading = true
			state.isUpdateInvoicesAccountSuccess = false
		},

		updateInvoicesAccountSuccess: (state) => {
			state.isUpdateInvoicesAccountLoading = false
			state.isUpdateInvoicesAccountSuccess = true
		},

		updateInvoicesAccountReset: (state) => {
			state.isUpdateInvoicesAccountLoading = false
			state.isUpdateInvoicesAccountSuccess = false
		},
		updateInvoiceEditStatusRequest: (state, { payload } : PayloadAction<IInvoice>) => {
			state.lastModifiedInvoiceId = undefined
		},
		updateInvoiceEditStatusSuccess: (state, { payload } : PayloadAction<IInvoice>) => {
			state.lastModifiedInvoiceId = payload.id
		},
		updateInvoiceEditStatusFailure: (state) => {
			state.lastModifiedInvoiceId = undefined
		}
	},
})

export const {
	getInvoiceRequest,
	createInvoiceRequest,
	updateInvoiceRequest,
	deleteInvoicesRequest,
	deleteInvoicesRequestSuccess,
	deleteInvoicesRequestFailure,

	updateInvoiceStatusRequest,
	updateInvoiceStatusRequestComplete,
	updateInvoicesReadyToPayRequest,
	updateInvoicesReadyToPaySuccess,
	updateInvoicesReadyToPayReset,
	updateInvoicesAccountRequest,
	updateInvoicesAccountSuccess,
	updateInvoicesAccountReset,

	cloneInvoicesRequest,
	cloneInvoicesRequestSuccess,
	archiveInvoicesRequest,
	archiveInvoicesRequestSuccess,
	reviewInvoicesRequest,
	reviewInvoicesRequestSuccess,
	reviewInvoicesRequestComplete,
	downloadInvoicePdfRequest,
	downloadInvoicePdfRequestComplete,
	getInvoiceReviewsRequest,
	invoiceReviewsRequestSuccess,
	invoiceReviewsRequestFailure,
	getInvoiceChangelogsRequest,
	invoiceChangelogsRequestSuccess,
	invoiceChangelogsRequestFailure,

	invoiceRequestSuccess,
	invoiceRequestComplete,

	setResetInvoiceData,

	setCurrentInvoiceToken,
	setCurrentInvoiceTotalAmount,
	setCurrentInvoiceTokens,
	setInvoiceComment,
	setInvoiceCustomId,
	setCurrentInvoiceUserReview,
	setCurrentInvoiceReceiverAccountId,

	setCreateInvoiceModalOpened,

	deleteInvoiceDocumentRequest,
	downloadInvoiceDocumentRequest,
	updateInvoiceEditStatusRequest,
	updateInvoiceEditStatusFailure,
	updateInvoiceEditStatusSuccess,
} = invoiceSlice.actions

export const { reducer } = invoiceSlice
