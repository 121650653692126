import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'
import fileDownload from 'js-file-download'
import { onOpenNotification } from 'redux/notification/reducer'

import { IInvoice } from '../types'
import { downloadInvoicePdfRequestComplete } from '../reducer'

export function* downloadInvoicePdfSaga({
	payload: { id, invoice_id, status },
}: PayloadAction<Pick<IInvoice, 'id' | 'invoice_id' | 'status'>>) {
	try {
		const pdf: ArrayBuffer = yield call(apiMiddleware, {
			url: app_config.services.downloadInvoicePdf,
			method: 'POST',
			data: { id },
			responseType: 'blob',
		})

		fileDownload(
			pdf,
			`invoice-${invoice_id}-${status}-${id}.pdf`,
			'application/pdf'
		)

		yield put(downloadInvoicePdfRequestComplete())
	} catch (e) {
		yield put(downloadInvoicePdfRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
