/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFormInputs } from 'components/SignInUpForm'
import { IRegistrationState } from './types'

const initialState: IRegistrationState = {
	loading: false,
	registrationSuccess: false,
}

const registrationSlice = createSlice({
	name: 'registration',
	initialState,
	reducers: {
		sendRegistrationDataRequest: (
			state,
			{ payload }: PayloadAction<IFormInputs>
		) => {
			state.loading = true
			state.registrationSuccess = false
		},
		sendRegistrationDataSuccess: (state) => {
			state.loading = false
			state.registrationSuccess = true
		},
		sendRegistrationDataFailure: (state) => {
			state.loading = false
			state.registrationSuccess = false
		},
	},
})

export const {
	sendRegistrationDataRequest,
	sendRegistrationDataSuccess,
	sendRegistrationDataFailure,
} = registrationSlice.actions

export const { reducer } = registrationSlice
