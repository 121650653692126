/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProtocolEnumType } from 'redux/invoice/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import { allStatuses } from 'utils/invoiceUtils'
import { IInvoicesReadyToPay, IInvoicesState } from './types'

const initialState: IInvoicesState = {
	invoices: [],
	invoicesUserReviews: undefined,
	invoicesUserReviewsCount: undefined,
	invoicesCount: {
		readyToPay: 0,
	},
	invoicesInArchive: 0,
	invoicesTotal: [],
	isFirstLoading: true,
	isLoading: false,
	filters: {
		status: allStatuses,
		searchInput: '',
		sortBy: '',
		direction: '',
		page: 0,
		token: WalletAccountBalanceToken.NOT_DEFINED,
		protocol: ProtocolEnumType.NOT_DEFINED,
		dateRange: null,
		userId: undefined,
		tags: [],
		archive: false,
	},

	isInvoicesReadyToPayLoading: false,
	invoicesReadyToPay: {
		invoices: [],
		protocols: [],
		tokens: [],
	},
}

const invoicesSlice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		getInvoicesRequest: (
			state,
			{
				payload,
			}: PayloadAction<Pick<IInvoicesState, 'filters' | 'isFirstLoading'>>
		) => {
			state.isLoading = true
			state.isFirstLoading = payload.isFirstLoading ?? false
		},

		getInvoicesSuccess: (
			state,
			{
				payload,
			}: PayloadAction<
				Pick<
					IInvoicesState,
					'invoices' | 'invoicesCount' | 'invoicesInArchive' | 'invoicesTotal' | 'filters' | 'invoicesUserReviews' | 'invoicesUserReviewsCount'
				>
			>
		) => {
			state.invoices = payload.invoices
			state.invoicesCount = payload.invoicesCount
			state.invoicesTotal = payload.invoicesTotal
			state.invoicesInArchive = payload.invoicesInArchive
			state.invoicesUserReviews = payload.invoicesUserReviews
			state.invoicesUserReviewsCount = payload.invoicesUserReviewsCount
			state.filters = payload.filters
			state.isLoading = false
			state.isFirstLoading = false
		},

		getInvoicesFailure: (state) => {
			state.isLoading = false
			state.isFirstLoading = false
		},

		getInvoicesReadyToPayRequest: (state) => {
			state.isInvoicesReadyToPayLoading = true
		},

		getInvoicesReadyToPaySuccess: (
			state,
			{ payload }: PayloadAction<IInvoicesReadyToPay>
		) => {
			state.invoicesReadyToPay = payload
			state.isInvoicesReadyToPayLoading = false
		},

		getInvoicesReadyToPayFailure: (state) => {
			state.isInvoicesReadyToPayLoading = false
		},

		setInvoicesFirstLoading: (state) => {
			state.isFirstLoading = true
		},

		resetInvoicesFilters: (state) => {
			state.filters = {
				status: allStatuses,
				searchInput: '',
				sortBy: '',
				direction: '',
				page: 0,
				token: WalletAccountBalanceToken.NOT_DEFINED,
				protocol: ProtocolEnumType.NOT_DEFINED,
				dateRange: null,
				userId: undefined,
				tags: [],
				archive: false,
			}
		},
	},
})

export const {
	getInvoicesRequest,
	getInvoicesSuccess,
	getInvoicesFailure,

	getInvoicesReadyToPayRequest,
	getInvoicesReadyToPaySuccess,
	getInvoicesReadyToPayFailure,

	setInvoicesFirstLoading,

	resetInvoicesFilters,
} = invoicesSlice.actions

export const { reducer } = invoicesSlice
