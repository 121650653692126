import { call, put } from 'redux-saga/effects'
import { setEmail } from 'redux/verification/reducer'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'

import { IFormInputs } from 'components/SignInUpForm'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'

import {
	sendRegistrationDataFailure,
	sendRegistrationDataSuccess,
} from './reducer'

export function* sendRegistrationDataSaga({
	payload,
}: PayloadAction<IFormInputs>) {
	try {
		const {
			message,
			email,
		}: {
			message: string
			email: string
		} = yield call(authMiddleware, {
			url: app_config.services.sendRegistration,
			data: payload,
		})

		yield put(setEmail(email))
		yield put(sendRegistrationDataSuccess())

		yield put(onOpenNotification({ message, notificationType: 'success' }))
	} catch (e) {
		yield put(sendRegistrationDataFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
