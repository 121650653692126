import { call, put } from 'redux-saga/effects'
import authMiddleware from 'services/auth_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import {
	setAuthFormLoading,
	setRequestCompletedFailure,
	setRequestCompletedSuccess,
} from 'redux/authForm/reducer'

export function* forgotPasswordSaga({
	payload,
}: PayloadAction<{ email: string }>) {
	try {
		yield put(setAuthFormLoading({ isLoading: true }))

		const { message }: { message: string } = yield call(authMiddleware, {
			url: app_config.services.forgotPassword,
			data: payload,
			method: 'POST',
		})

		yield put(setRequestCompletedSuccess())

		yield put(onOpenNotification({ message, notificationType: 'success' }))
	} catch (e) {
		yield put(setRequestCompletedFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
