import { call, put, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { IStore } from 'redux/types'

import { onOpenNotification } from 'redux/notification/reducer'

import { tokenCoinPrices } from 'utils/invoiceUtils'
import {
	IInvoice,
	IInvoiceState,
	IInvoiceUserReview,
	IUpdateInvoiceFunc,
} from '../types'
import {
	invoiceRequestComplete,
	invoiceRequestSuccess,
	setCurrentInvoiceUserReview,
	updateInvoiceEditStatusFailure,
	updateInvoiceEditStatusSuccess,
	updateInvoiceStatusRequestComplete,
} from '../reducer'

export function* updateInvoiceSaga({
	payload,
}: PayloadAction<IUpdateInvoiceFunc>) {
	try {
		const { currentInvoice }: IInvoiceState = yield select(
			(state: IStore) => state.invoice
		)
		const { coinPrices } = yield select(
			(state: IStore) => state.coinPrices
		)
		const {
			id,
			token,
			tokens,
			comment,
			total_amount,
			custom_invoice_id,
			receiver_account_id,
		} = currentInvoice

		const {
			data,
		}: {
			message: string
			data: {
				invoice: IInvoice
				review?: IInvoiceUserReview
			}
		} = yield call(apiMiddleware, {
			url: app_config.services.updateInvoice,
			method: 'POST',
			data: {
				id,
				token,
				tokens,
				comment,
				total_amount,
				custom_invoice_id,
				receiver_account_id,
				status: payload.status,
				token_price: coinPrices[tokenCoinPrices[token]].usd,
			},
		})

		yield put(setCurrentInvoiceUserReview(data.review))
		yield put(invoiceRequestSuccess(data.invoice))
		yield put(updateInvoiceStatusRequestComplete())
	} catch (e) {
		yield put(invoiceRequestComplete())
		yield put(updateInvoiceStatusRequestComplete())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
export function* updateInvoiceEditStatusSaga({ payload }: PayloadAction<IInvoice>) {
	try {

		const { data }: {
			message: string
			data: any
		} = yield call(apiMiddleware, {
			url: app_config.services.updateInvoice,
			method: 'POST',
			data: {
				...payload
			},
		})

		yield put(updateInvoiceEditStatusSuccess(data.invoice))
	} catch (e) {
		yield put(updateInvoiceEditStatusFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
