import { call, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { onOpenNotification } from 'redux/notification/reducer'
import { IInviteMemberRequest } from '../types'
import { inviteMembersFailure, inviteMembersSuccess } from '../reducer'

export function* inviteMembersSaga({
	payload,
}: PayloadAction<IInviteMemberRequest>) {
	try {
		const data: { message: string } = yield call(apiMiddleware, {
			url: app_config.services.inviteMembers,
			data: payload,
			method: 'POST',
		})

		yield put(
			onOpenNotification({
				message: data.message,
				notificationType: 'success',
			})
		)

		yield put(inviteMembersSuccess())
	} catch (e) {
		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)

		yield put(inviteMembersFailure())
	}
}
