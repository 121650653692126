import { call, put, select } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'
import { IInvoice, IInvoicesUserReviews, IStatusType } from 'redux/invoice/types'

import { IStore } from 'redux/types'
import { onOpenNotification } from 'redux/notification/reducer'

import { getInvoicesFailure, getInvoicesSuccess } from '../reducer'
import { IInvoicesState } from '../types'

export function* getInvoicesSaga({
	payload,
}: PayloadAction<Pick<IInvoicesState, 'filters'>>) {
	try {
		const { filters, invoices } = yield select(
			(state: IStore) => state.invoices
		)

		const data = {
			...filters,
			...payload.filters,
			page: payload.filters?.page ?? 0,
		}

		const response: {
			data: {
				invoices: IInvoice[]
				invoicesCount: {
					[key in IStatusType]?: number
				}
				invoicesTotal: {
					amount: number
					token: WalletAccountBalanceToken
				}[],
				invoicesInArchive: number,
				invoicesUserReviews?: IInvoicesUserReviews
				invoicesUserReviewsCount?: number
			}
		} = yield call(apiMiddleware, {
			url: app_config.services.getInvoices,
			method: 'POST',
			data,
		})

		yield put(
			getInvoicesSuccess({
				invoices: data.page
					? [...invoices, ...response.data.invoices]
					: response.data.invoices,
				invoicesCount: response.data.invoicesCount,
				invoicesTotal: response.data.invoicesTotal,
				invoicesInArchive: response.data.invoicesInArchive,
				invoicesUserReviews: response.data.invoicesUserReviews,
				invoicesUserReviewsCount: response.data.invoicesUserReviewsCount,
				filters: data,
			})
		)
	} catch (e) {
		const error = e as { message: string }

		yield put(getInvoicesFailure())

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
