import { IFormInputs } from 'components/SignInUpForm'
import { call, put } from 'redux-saga/effects'
import { getUserSuccess } from 'redux/user/reducer'
import { IUser } from 'redux/user/types'
import apiMiddleware from 'services/api_middleware'
import app_config from 'config/app_config'

import { PayloadAction } from '@reduxjs/toolkit'
import { onOpenNotification } from 'redux/notification/reducer'
import { otpVerifyFailure, otpVerifySuccess } from 'redux/authForm/reducer'

export function* otpVerifySaga({
	payload,
}: PayloadAction<Pick<IFormInputs, 'otpToken'>>) {
	try {
		const response: {
			message: string
			data: { user: IUser }
		} = yield call(apiMiddleware, {
			url: app_config.services.otpVerify,
			data: payload,
			method: 'POST',
		})

		yield put(getUserSuccess(response.data.user))
		yield put(otpVerifySuccess())

		yield put(
			onOpenNotification({
				message: response.message,
				notificationType: 'success',
			})
		)
	} catch (e) {
		yield put(otpVerifyFailure())

		const error = e as { message: string }

		yield put(
			onOpenNotification({
				message: error.message,
				notificationType: 'error',
			})
		)
	}
}
